import { createApp } from 'vue'
import NucoroRelationshipManager from '@nucoro/relationship-manager'
import router from './router'
import config from './config'
import elements from './elements'
import messages from '@/i18n/messages'
import { getStages, getSteps } from '@/models/onboarding'

import '@/assets/index.css'
import App from './App.vue'

const getBrowserLanguage = () => {
  const browserLanguage = navigator.language.split('-')[0]
  return config.app.languages.find(({ key }) => key.startsWith(browserLanguage))?.key
}

createApp(App)
  .use(NucoroRelationshipManager, {
    config,
    i18n: { locale: getBrowserLanguage() || config.app.locale, messages },
    onboarding: { steps: getSteps(), stages: getStages() },
    elements,
    router
  })
  .use(router)
  .mount('#app')
