export default {
  app: {
    locale: 'fr-ch',
    languages: [
      { key: 'en-ch', name: 'English', shortName: 'En' },
      { key: 'fr-ch', name: 'Français', shortName: 'Fr' },
      { key: 'de-ch', name: 'Deutsch', shortName: 'De' },
      { key: 'it-ch', name: 'Italiano', shortName: 'It' }
    ],
    clientLanguages: [
      { key: 'en-ch', name: 'English', shortName: 'En' },
      { key: 'fr-ch', name: 'Français', shortName: 'Fr' },
      { key: 'de-ch', name: 'Deutsch', shortName: 'De' },
      { key: 'it-ch', name: 'Italiano', shortName: 'It' }
    ],
    countriesCodes: ['ch', 'fr', 'de', 'it'],
    showAllCountries: true,
    showBreakdownFees: true,
    sections: {
      resetPassword: true,
      dashboard: true,
      client: ['overview', 'portfolios', 'activity', 'documents', 'investor', 'profile'],
      clientDocuments: ['add', 'general', 'invoices', 'statements', 'tax-reports'],
      clients: true,
      discount: false,
      mfa: true,
      onboarding: true,
      portfolios: [],
      selfTrading: false,
      watchlist: false
    },
    investorAreaHost: process.env.VUE_APP_INVESTOR_AREA_HOST,
    referralLink: ['uuid']
  },
  api: {
    host: process.env.VUE_APP_API_HOST,
    timeout: 300000
  },
  brand: {
    name: 'Opsion',
    nameAttached: 'Fondation Libre Passage',
    nameLegal: 'Opsion LP',
    shortName: 'Opsion',
    address: 'Passage Saint-Antoine 7, 1800 Vevey (Suisse)',
    phone: '+41 (0) 583 204 031'
  },
  provider: {
    supportEmail: process.env.VUE_APP_PROVIDER_SUPPORT,
    supportPhone: process.env.VUE_APP_PROVIDER_SUPPORT_PHONE
  },
  portfolio: {
    adviceEngine: 'model_portfolio',
    advisorGroup: 'opsion',
    defaultType: 'LP',
    initialAmount: 100000,
    maximumInitialAmount: 10000000,
    showPerformance: false,
    timeHorizonDefault: 25
  },
  allocation: {
    stackedChart: 'assetClasses',
    donutChart: 'assetCategories',
    tablePositionList: 'assetClasses',
    assetClassHierarchy: [
      'CASH',
      'MONEY_MARKET',
      'BONDS',
      'ALTERNATIVE_INVESTMENTS',
      'MULTI_ASSETS',
      'EQUITIES',
      'REAL_ESTATE',
      'UNDEFINED'
    ],
    avatar: true,
    assetDetail: false,
    priceVariation: false
  },
  documents: {
    host: process.env.VUE_APP_DOCUMENT_HOST,
    legalDocumentBeneficiary: '_HOST_/beneficiaire__LANG_.pdf',
    legalDocumentFees: '_HOST_/fees__LANG_.pdf',
    legalDocumentInvestmentRegulations: '_HOST_/investment_regulations__LANG_.pdf',
    legalDocumentPensionFundRegulations: '_HOST_/pension_fund_regulations__LANG_.pdf',
    userContract: '' // get it from the API
  }
}
