import { interceptors } from '@nucoro/relationship-manager'

export default [
  {
    path: '/client/:client',
    name: 'client',
    component: () => import(/* webpackChunkName: "client" */ '@/views/client'),
    redirect: { name: 'client-preview' },
    beforeEnter: [
      interceptors.requiresAuthentication,
      interceptors.fetchManager,
      interceptors.fetchClientByUuid,
      interceptors.fetchSettings,
      interceptors.fetchPortfolios,
      interceptors.removesPendingAssessments
    ],
    children: [
      {
        path: 'overview',
        name: 'client-overview',
        component: () => import(/* webpackChunkName: "client-overview" */ '@/views/client/overview')
      },
      {
        path: 'portfolios',
        name: 'client-portfolios',
        component: () => import(/* webpackChunkName: "client-portfolios" */ '@/views/client/portfolios')
      },
      {
        path: 'activity',
        name: 'client-activity',
        component: () => import(/* webpackChunkName: "client-activity" */ '@/views/client/activity')
      },
      {
        path: 'documents',
        name: 'client-documents',
        component: () => import(/* webpackChunkName: "client-documents" */ '@/views/client/documents')
      },
      {
        path: 'investor',
        name: 'client-investor',
        component: () => import(/* webpackChunkName: "client-investor" */ '@/views/client/investor')
      },
      {
        path: 'profile',
        name: 'client-profile',
        component: () => import(/* webpackChunkName: "client-profile" */ '@/views/client/profile')
      }
    ]
  },
  {
    path: '/client/:client/preview',
    name: 'client-preview',
    component: () => import(/* webpackChunkName: "client-preview" */ '@/views/client/client-preview'),
    beforeEnter: [
      interceptors.requiresAuthentication
    ]
  }
]
