import { interceptors } from '@nucoro/relationship-manager'

const routes = [
  {
    path: '/client/:client/portfolio/:portfolio/edit',
    name: 'portfolio-edit',
    component: () => import(/* webpackChunkName: "portfolio-edit" */ '@/views/portfolio-setup')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.requiresAuthentication,
    interceptors.fetchManager,
    interceptors.fetchClientByUuid,
    interceptors.fetchSettings,
    interceptors.fetchPortfolioByUuid
  ]
}))
