import { models } from '@nucoro/relationship-manager'

const state = {
  stages: [
    { id: 'goals', name: 'ONBOARDING_STAGE_GOALS', number: 1 },
    { id: 'portfolio', name: 'ONBOARDING_STAGE_PORTFOLIO', number: 2 },
    { id: 'personal-info', name: 'ONBOARDING_STAGE_INFO', number: 3 }
  ],
  steps: [
    { id: 'name', number: 1, stageIndex: 0 },
    { id: 'date-of-birth', number: 2, stageIndex: 0 },
    { id: 'goal', number: 3, stageIndex: 0, assessment: true },
    { id: 'time-horizon', number: 4, stageIndex: 0, assessment: true },
    { id: 'outcome', number: 5, stageIndex: 0, assessment: true },
    { id: 'loss-tolerance', number: 6, stageIndex: 0, assessment: true },
    { id: 'assets-held', number: 7, stageIndex: 0, assessment: true },
    { id: 'trading-method', number: 8, stageIndex: 0, assessment: true },
    { id: 'risk-result', number: 9, stageIndex: 0, assessment: true },

    { id: 'model-portfolio', number: 1, stageIndex: 1 },
    { id: 'portfolio-summary', number: 2, stageIndex: 1 },

    { id: 'phone', number: 1, stageIndex: 2 },
    { id: 'address', number: 2, stageIndex: 2 },
    { id: 'marital-status', number: 3, stageIndex: 2 },
    { id: 'kids', number: 4, stageIndex: 2 },
    { id: 'avs-number', number: 5, stageIndex: 2 },
    { id: 'upload-id', number: 6, stageIndex: 2 },
    { id: 'fees', number: 7, stageIndex: 2 },
    { id: 'final', number: 7, stageIndex: 2 }
  ]
}

export const getStages = () => state.stages
export const getSteps = () => state.steps

export const init = () => {
  models.onboarding.setSteps(getSteps())
  models.onboarding.setStages(getStages())
}

/**
 * Get onboarding / retake assessment set of routes between a range
 * @param {Object} data, { from, to, prefix, meta }
 * @returns {Array}
 */
export const getRoutes = ({ from = '', to = '', prefix = 'onboarding', meta = {} }) => {
  init()
  return models.onboarding.getRoutes({ from, to, prefix, meta })
    .map(route => ({
      ...route,
      // include `client` as route param
      path: route.path.replace(`/${prefix}/`, `/${prefix}/:client/`),
      component: () => import(
        /* webpackChunkName: "onboarding" */ `@/views/onboarding/${route.name.replace(`${prefix}-`, '')}`
      )
    }))
}
