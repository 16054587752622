import { interceptors } from '@nucoro/relationship-manager'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/clients')
  },
  {
    path: '/create-client',
    name: 'create-client',
    component: () => import(/* webpackChunkName: "create-client" */ '@/views/create-client')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.requiresAuthentication,
    interceptors.fetchManager,
    interceptors.fetchSettings
  ]
}))
