import { interceptors, use } from '@nucoro/relationship-manager'
import { getRoutes } from '@/models/onboarding'

const routes = [
  {
    path: '/onboarding/:client',
    name: 'onboarding',
    redirect: ({ params }) => ({ name: 'onboarding-name', params })
  },
  // stage 1
  ...getRoutes({ from: 'name', to: 'model-portfolio' }),
  // stage 2
  ...getRoutes({ from: 'model-portfolio', to: 'phone', meta: { configuresPortfolio: true } }),
  // stage 3
  ...getRoutes({ from: 'phone', to: 'final' }),
  {
    path: '/onboarding/:client/final',
    name: 'onboarding-final',
    component: () => import(/* webpackChunkName: "onboarding-final" */ '@/views/onboarding/final'),
    beforeEnter: [
      interceptors.requiresAuthentication,
      interceptors.fetchClientByUuid,
      interceptors.requiresOnboardingPending,
      interceptors.fetchManager
    ]
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: route.beforeEnter || [
    interceptors.requiresAuthentication,
    interceptors.fetchClientByUuid,
    interceptors.requiresOnboardingPending,
    interceptors.fetchManager,
    interceptors.fetchSettings,
    interceptors.setLedOnboarding,
    interceptors.fetchPortfolioData,
    interceptors.managesAssessmentOutdated,
    interceptors.initOnboardingState,
    interceptors.checkOnboardingStep,
    to => use.onboardingProgress.updateProgressFromRoute(to.name)
  ]
}))
