export default {
  components: [
    'NcButtonHighlighted',
    'NcInput',
    'NcInputSlider',
    'NcModal'
  ],
  widgets: [
    'NcStepperQuestion'
  ],
  layouts: [
    'NcAuthLayout',
    'NcOnboardingLayout',
    'NcStepperLayout',
    'RmDetailLayout'
  ],
  views: [
    'NcAccountLocked',
    'NcForcedMfa',
    'NcListSkeleton',
    'NcLogin',
    'NcLoginMfa',
    'NcModelPortfolio',
    'NcNotFound',
    'NcOnboardingAddress',
    'NcOnboardingAssetsHeld',
    'NcOnboardingAvsNumber',
    'NcOnboardingDateOfBirth',
    'NcOnboardingGoal',
    'NcOnboardingKids',
    'NcOnboardingLossTolerance',
    'NcOnboardingMaritalStatus',
    'NcOnboardingModelPortfolio',
    'NcOnboardingName',
    'NcOnboardingOutcome',
    'NcOnboardingPhone',
    'NcOnboardingPortfolioSummary',
    'NcOnboardingRiskResult',
    'NcOnboardingTradingMethod',
    'NcOnboardingUploadId',
    'NcPasswordForgotten',
    'NcPasswordForgottenSuccess',
    'NcPasswordReset',
    'NcPortfolioSummary',
    'NcProfileChangePassword',
    'NcProfileMfa',
    'NcTokenExpired',
    'RmClient',
    'RmClientActivity',
    'RmClientDocuments',
    'RmClientDocumentsGeneral',
    'RmClientDocumentsInvoices',
    'RmClientDocumentsStatements',
    'RmClientDocumentsTaxReports',
    'RmClientInvestor',
    'RmClientOverview',
    'RmClientPortfolios',
    'RmClientPortfoliosDetail',
    'RmClientPreview',
    'RmClientProfile',
    'RmClients',
    'RmCreateClient',
    'RmDashboard',
    'RmOnboardingFinal'
  ]
}
