import publicRoutes from './public'
import privateRoutes from './private'
import clientAreaRoutes from './client-area'
import onboardingRoutes from './onboarding'
import portfolioSetupRoutes from './portfolio-setup'

export default [
  ...publicRoutes,
  ...privateRoutes,
  ...clientAreaRoutes,
  ...onboardingRoutes,
  ...portfolioSetupRoutes,
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found'
  }
]
