import { interceptors } from '@nucoro/relationship-manager'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login')
  },
  {
    path: '/login-mfa',
    name: 'login-mfa',
    component: () => import(/* webpackChunkName: "login-mfa" */ '@/views/login-mfa')
  },
  {
    path: '/forced-mfa',
    name: 'forced-mfa',
    component: () => import(/* webpackChunkName: "forced-mfa" */ '@/views/forced-mfa')
  },
  {
    path: '/account-locked',
    name: 'account-locked',
    component: () => import(/* webpackChunkName: "account-locked" */ '@/views/account-locked')
  },
  {
    path: '/password-forgotten',
    name: 'password-forgotten',
    component: () => import(/* webpackChunkName: "password-forgotten" */ '@/views/password-forgotten')
  },
  {
    path: '/reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '@/views/password-reset')
  },
  {
    path: '/password-forgotten-success',
    name: 'password-forgotten-success',
    component: () => import(
      /* webpackChunkName: "password-forgotten-success" */ '@/views/password-forgotten-success'
    )
  },
  {
    path: '/token-expired',
    name: 'token-expired',
    component: () => import(/* webpackChunkName: "token-expired" */ '@/views/token-expired')
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found')
  }
]

export default routes.map(route => ({
  ...route,
  beforeEnter: [
    interceptors.impersonate
  ]
}))
